@import "owl.carousel/dist/assets/owl.carousel.css";
@import "owl.carousel/dist/assets/owl.theme.default.css";

.error {
  color: red;
}

.category-image {
  max-width: 80% !important;
}

.single-categories-box {
  height: 240px;
  margin-top: 2px;
}

.owl-carousel .owl-item img {
  display: inline-block !important;
  width: 100%;
  max-width: 100%;
}

.image-container {
  background-color: #f7f8fa;
  width: 100% !important;
  justify-content: center;
  align-items: center;
  display: flex;
}

.single-products-box .image {
  height: 300px;
  display: flex;
  justify-content: center;
}

.single-products-promotions-box img {
  width: 20%;
  position: absolute;
  bottom: 15px;
  left: 20px;
}
.single-products-promotions-box {
  overflow: inherit;
  height: 190px;
}

.logo {
  height: 40px;
  width: 150px;
}

.single-banner-categories-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  border-radius: 5px;
}
:root {
  --fontFamily: "Open Sans", sans-serif;
  --fontFamily2: "Hind Siliguri", sans-serif;
  --mainColor: green;
  --optionalColor: #696969;
  --whiteColor: #ffffff;
  --blackColor: #111111;
  --fontSize: 15px;
  --transition: 0.5s;
}
.single-banner-categories-box .category-banner-image {
  width: 30%;
  height: 140px;
}

.single-banner-categories-box .content {
  position: relative;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.products-slides .see-more {
}

.products-slides .section-title {
}

.productsQuickView
  .modal-content
  .products-content
  .products-size-wrapper
  ul
  li
  a,
.products-details-desc .products-size-wrapper ul li a {
  width: 55px;
}

.delivery-charge {
  font-size: 11px !important;
  color: red;
  display: inline;
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-title a {
  margin-right: 90px;
  text-decoration: underline;
}
.brand-item-box {
  margin-bottom: 30px;
  position: relative;
  border-radius: 3px;
  text-align: center;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 7px rgb(0 0 0 / 10%);
  box-shadow: 0 0 7px rgb(0 0 0 / 10%);
  padding: 30px 15px;
}

.brand-item-box h3 {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.single-brands-item {
  margin-bottom: 0px;
  text-align: center;
  border-right: 0px solid #eeeeee;
}

/*  bhoechie tab */
div.bhoechie-tab-container {
  display: flex;
  z-index: 10;
  background-color: #ffffff;
  padding: 0 !important;
  border-radius: 4px;
  -moz-border-radius: 4px;
  /* border: 1px solid #ddd; */
  margin-top: 20px;
  /* -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); */
  background-clip: padding-box;
  opacity: 0.97;
  filter: alpha(opacity=97);
}
div.bhoechie-tab-menu {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}
div.bhoechie-tab-menu div.list-group {
  margin-bottom: 0;
}
div.bhoechie-tab-menu div.list-group > a {
  margin-bottom: 0;
}
div.bhoechie-tab-menu div.list-group > a .glyphicon,
div.bhoechie-tab-menu div.list-group > a .fa {
  color: var(--mainColor);
}
div.bhoechie-tab-menu div.list-group > a:first-child {
  border-top-right-radius: 0;
  -moz-border-top-right-radius: 0;
}
div.bhoechie-tab-menu div.list-group > a:last-child {
  border-bottom-right-radius: 0;
  -moz-border-bottom-right-radius: 0;
}
div.bhoechie-tab-menu div.list-group > a.active,
div.bhoechie-tab-menu div.list-group > a.active .glyphicon,
div.bhoechie-tab-menu div.list-group > a.active .fa {
  background-color: var(--mainColor);
  background-image: var(--mainColor);
  color: #ffffff;
}
div.bhoechie-tab-menu div.list-group > a.active:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -13px;
  border-left: 0;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
  border-left: 10px solid var(--mainColor);
}

div.bhoechie-tab-content {
  background-color: #ffffff;
  /* border: 1px solid #eeeeee; */
  padding-left: 20px;
  padding-top: 10px;
}

div.bhoechie-tab div.bhoechie-tab-content:not(.active) {
  display: none;
}

.display-flex {
  display: flex;
}
.swal-button--confirm:hover .swal-button--confirm {
  background-color: green;
}
